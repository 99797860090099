import React from 'react'
import Home from './Components/Home'

function App() {

  return (
    <Home/>
  );
}

export default App;
