import React from "react";
// import Timer from './Timer'

const Home = () => {
  return (
    <div className="display">
      <div className="name-display">
      <div className='namesHome'>
        <p className='Name' id='Issra'>Issra</p>
        <span className='and'>&</span>
        <p className='Name'>Hamza</p>
      </div>
      {/* <Timer/> */}
      {/* <p className="loading"></p> */}
      </div>
    </div>
  )
}

export default Home